<template>
  <div class="mt-5">
    <h4 style="text-align: center;">{{ data.title }}</h4>
    <v-card>
      <v-img src="https://assets.volvo.com/is/image/VolvoInformationTechnologyAB/B510R%20(11)?size=1280,720&scl=1" height="200px" cover />
      <v-overlay :absolute="absolute" :opacity="opacity" :value="overlay">
        <v-btn fab small color="red lighten-2" @click="showModal">
          <v-icon>mdi-play</v-icon>
        </v-btn>
      </v-overlay>
    </v-card>
    <VideoPlayer :videoSrc="data.video" :title="data.title" :show="isShowModal" @showModal="showModal"  />
  </div>
</template>


<script>
import VideoPlayer from './VideoPlayer.vue';

export default {
  name: "VideoCard",
  props: ['data'],
  data: () => ({
    absolute: true,
    opacity: 0.7,
    overlay: true,
    isShowModal: false
  }),
  methods: {
    showModal() {
      this.isShowModal = !this.isShowModal
    }
  },
  components: { VideoPlayer }
}
</script>
