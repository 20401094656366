<template>
  <v-container style="margin-top: 28px;">
    <v-tabs v-model="tab" background-color="transparent" color="basil" grow>
      <v-tab href="#tab-1">
        Passagens
      </v-tab>
      <v-tab href="#tab-2">
        Encomendas
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item value="tab-1">
        <v-row>
          <v-col cols="12" sm="6" md="3" v-for="data, ind  in videoData.passagens" :key="ind">
            <VideoCard :data="data" />
          </v-col>

        </v-row>
      </v-tab-item>
      <v-tab-item value="tab-2">
        <v-row>
          <v-col cols="12" sm="6" md="3" v-for="data, indx in videoData.encomendas" :key="indx">
            <VideoCard :data="data" />
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>


  </v-container>
</template>


<script>
import VideoCard from './components/VideoCard.vue';
export default {
  components: {
    VideoCard
  },
  data: () => ({
    tab: null,
    videoData: {
      passagens: [
        { "title": "Cancelamento de Passagens", video: "passagens/cancelamento_passagens.mp4" },
        { "title": "Emissão de Excesso de Bagagem", video: "passagens/emissao_excesso_bagagem.mp4" },
        { "title": "Reimpressão de Passagem", video: "passagens/reimpressao_passagem.mp4" },
        { "title": "Remarcação de Passagem", video: "passagens/remarcacao_passagem.mp4" },
        { "title": "Venda de Passagem Casadinha", video: "passagens/venda_passagem_casadinha.mp4" },
        { "title": "Venda de Passagem Em Pé", video: "passagens/venda_passagem_em_pe.mp4" },
        { "title": "Venda de Passagem Mais Criança", video: "passagens/venda_passagem_mais_crianca.mp4" },
        { "title": "Venda de Passagem Normal", video: "passagens/venda_passagem_normal.mp4" }
      ],
      encomendas: [
        { "title": "Emissão de encomendas", video: "encomendas/emissao_encomendas.mp4" },
        { "title": "Entrega de encomenda para o cliente", video: "encomendas/entrega_encomenda_para_cliente.mp4" },
        { "title": "Envio de encomenda", video: "encomendas/envio_encomenda.mp4" },
        { "title": "Movimentar encomenda", video: "encomendas/movimentar_encomenda.mp4" },
      ],
    }
  })
}
</script>
