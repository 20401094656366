<template>
  <div class="text-center">
    <v-dialog v-model="show" persistent width="690">
      <v-card>
        <v-card-title class="text-h5 primary">
          <span style="color: #ffffff;">{{ title }}</span>
        </v-card-title>
        <v-card-text class=" mt-6">

          <video ref="videoPlayer" width="640" height="360" controls>
            <source :src="require(`@/assets/videos/${this.videoSrc}`)" type="video/mp4">
            Seu navegador não suporta o elemento video.
          </video>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="showModal">
            Sair
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
export default {
  name: 'VideoPlayer',
  props: ['show', 'title', 'videoSrc'],
  methods: {
    showModal() {
      this.$emit('showModal')
    }
  }
}
</script>
